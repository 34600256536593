#landing-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

#landing-top {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 50vh;
}

#landing-top > div {
    flex-grow: 1;
}

#landing-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

#login-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: gray;
    max-width: 50vh;
    height: 50vh;
    color: black;
}

#landing-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50vw;
}


.form-element {
    font-size: 1.5rem;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}