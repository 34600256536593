.stepThree {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  margin-left: 20%;

  .stepThree-header {
    margin-top: 10%;
    h1 {
      font-size: 45px;
      color: #90caf9;
      margin-bottom: 20%;
    }
  }

  .single-project-container {
    display: flex;
    align-items: center;
    .single-project-text{
      max-width: 20%;
      min-width: 20%;
      .single-project-header {
        color: #fff;
        h3 {
          max-width: 70%;
          line-height: 30px;
          margin: 0;
        }
      }
    .single-project-content {
      color: #c5c6c7;
      h3 {
        max-width: 70%;
        line-height: 30px;
        margin: 0;
      }
    }
  }

    .single-project-img {
      img {
        height: 500px;
        width: fit-content;
        margin-right: 1rem;
        box-shadow: 0px 51px 95px -49px rgba(255,255,255,0.2);
        border-radius: 25px;
      }
    }

  }

}
