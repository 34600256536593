.landing-container{
  background-color:midnightblue;

  .sections{
    width: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      display: none;
    }

    > * {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      color: #66FCF1;
      text-align: start;

    }
  }
}

