.stepTwo{
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  padding-bottom: 2rem;

  .team-header {
    margin-top: 2%;
      max-width: 50%;
      margin-bottom: 5%;

    h1 {
      font-size: 45px;
      color: #90caf9;
    }
  }
  .team-container {
    display: flex;
    align-items: center;

    .landing-profile-text {
      max-width: 20%;
        min-width: 20%;
    }

    .landing-profile-header {
      color: #fff;
    }

    .landing-profile-content {
      color: #c5c6c7;
    }

    h3 {
      max-width: 70%;
      line-height: 30px;
      margin: 0;
    }

    .landing-profile-img {

      img {
        height: 500px;
        width: fit-content;
        margin-right: 1rem;
        box-shadow: 0px 51px 95px -49px rgba(255,255,255,0.2);
        border-radius: 25px;
      }
    }
  }

  .landing-chat-container {
    display: flex;
    margin-top: 5%;

    .landing-chat-img {
      img {
        height: 500px;
        width: fit-content;
        margin-right: 1rem;
        box-shadow: 0px 51px 95px -44px rgba(255,255,255,0.2);
        border-radius: 25px;
      }
    }

    .landing-chat-text {
      max-width: 20%;
        min-width: 20%;
      margin-top: 5%;
    }

    .landing-chat-header {
      color: #fff;
    }

    .landing-chat-content {
      color: #c5c6c7;
    }

    h3 {
      max-width: 70%;
      line-height: 30px;
      margin: 0;
    }

  }
}
