body {
  background-color: #0b0c10;
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
}

#navbar {
  position: sticky;
}

.budget-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 0px rgba(78, 128, 185, 0.5);
  width: 15rem;
  margin-top: 10px;
}



.single-unit, .single-project, .single-cost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}



#deleting-unit, #deleting-project, #deleting-cost {
    background-color: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    border-radius: 8px;
}

.form-spacing {
  margin-bottom: 1rem;
}

#sign-out-button {
  color: inherit;
}

#sign-out-button:hover {
  color: #FFCCCB;
}


#scenarios::-webkit-scrollbar {
  display: none;
}

#x {
  position: absolute;
  top: -20px;
  right: -20px;
}

.display-row {
  display: flex;
  flex-direction: row;
}

.display-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.fetch-transactions {
  width: 12rem;
  height: 1.5rem;
}

.fetching-transactions {
  width: 9rem;
  height: 1.5rem;
}
